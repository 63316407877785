import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost/physic_backend/'
      : 'https://api.clinicaphysic.com.br/',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export default api;
