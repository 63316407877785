import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input } from '@rocketseat/unform';
import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import history from '~/services/history';
import api from '~/services/api';

import ImageInput from '~/components/ImageInput';
import Select from '~/components/SelectInput';

export default function Cadastrar() {
  const params = useParams();
  const { id } = params;

  const [editor, setEditor] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [autores, setAutores] = useState([]);
  const [imageCapa, setImageCapa] = useState([]);
  const [imageBanner, setImageBanner] = useState([]);
  const [materia, setMateria] = useState([]);

  useEffect(() => {
    ClassicEditor.create(document.querySelector('#editor'), {
      toolbar: {
        items: [
          'heading',
          '|',
          'fontFamily',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          'bold',
          'italic',
          'underline',
          'bulletedList',
          'numberedList',
          'todoList',
          '|',
          'alignment',
          'indent',
          'outdent',
          '|',
          'imageUpload',
          'blockQuote',
          'insertTable',
          'mediaEmbed',
          'undo',
          'redo',
          'link',
          'code',
        ],
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Parágrafo',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Cabeçalho 2',
            class: 'ck-heading_heading2',
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Cabeçalho 3',
            class: 'ck-heading_heading3',
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Cabeçalho 4',
            class: 'ck-heading_heading4',
          },
          {
            model: 'heading5',
            view: 'h5',
            title: 'Cabeçalho 5',
            class: 'ck-heading_heading5',
          },
          {
            model: 'heading6',
            view: 'h6',
            title: 'Cabeçalho 6',
            class: 'ck-heading_heading6',
          },
        ],
      },
      language: 'pt-br',
      image: {
        toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
      },
      licenseKey: '',
    }).then(newEditor => {
      setEditor(newEditor);
    });

    api.get('categories').then(response => {
      if (response.data.length > 0) {
        const data = response.data.map(categoria => ({
          id: categoria.id,
          title: categoria.name,
        }));
        setCategorias(data);
      } else {
        swal(
          'Falta algo!',
          'Não é possivel cadastrar uma materia sem ter categorias cadastradas',
          'error'
        ).then(_ => {
          history.push(`${process.env.PUBLIC_URL}/categorias/cadastrar`);
        });
      }
    });

    api.get('users').then(response => {
      if (response.data.length > 0) {
        const data = response.data.map(autor => ({
          id: autor.id,
          title: autor.name,
        }));

        setAutores(data);
      } else {
        swal(
          'Falta algo!',
          'Não é possivel cadastrar uma materia sem ter usuários cadastrados',
          'error'
        ).then(_ => {
          history.push(`${process.env.PUBLIC_URL}/usuarios/cadastrar`);
        });
      }
    });
  }, []);

  useEffect(() => {
    api.get(`matters/${id}`).then(response => {
      setMateria({
        titulo: response.data.title,
        meta_title: response.data.meta_title,
        categoria: response.data.category_id,
        autor: response.data.user_id,
        url: response.data.slug,
        meta_description: response.data.description,
        tags: response.data.tags,
        urlcapa: response.data.thumbnail,
        urlbanner: response.data.banner,
      });

      if (editor.id) {
        editor.setData(response.data.content);
      }

      const qtd_meta_title = response.data.meta_title.length;
      const qtd_diff_falta_meta_title = 30 - qtd_meta_title;

      if (qtd_diff_falta_meta_title > 0) {
        document
          .querySelector('.caracteres-meta-title')
          .classList.remove('text-success');
        document
          .querySelector('.caracteres-meta-title')
          .classList.add('text-danger');
        document.querySelector(
          '.caracteres-meta-title span.falta-resta'
        ).innerHTML = 'Faltam';

        document.querySelector(
          '.caracteres-meta-title span.caracteres-title'
        ).innerHTML = qtd_diff_falta_meta_title;

        document.querySelector(
          '.caracteres-meta-title span.catacter-falta-resta'
        ).innerHTML = 'caracteres';

        if (qtd_diff_falta_meta_title === 1) {
          document.querySelector(
            '.caracteres-meta-title  span.falta-resta'
          ).innerHTML = 'Falta';
          document.querySelector(
            '.caracteres-meta-title  span.catacter-falta-resta'
          ).innerHTML = 'caractere';
        }
      } else {
        document
          .querySelector('.caracteres-meta-title')
          .classList.remove('text-danger');
        document
          .querySelector('.caracteres-meta-title')
          .classList.add('text-success');
        const qtd_diff_resta_meta_title = 65 - qtd_meta_title;

        document.querySelector('span.falta-resta').innerHTML = 'Restam';

        document.querySelector(
          'span.caracteres-title'
        ).innerHTML = qtd_diff_resta_meta_title;

        document.querySelector('span.catacter-falta-resta').innerHTML =
          'caracteres';

        if (qtd_diff_resta_meta_title === 1) {
          document.querySelector('span.falta-resta').innerHTML = 'Resta';

          document.querySelector('span.catacter-falta-resta').innerHTML =
            'caractere';
        }
      }

      const qtd_meta_description = response.data.description.length;
      const qtd_diff_falta_meta_description = 120 - qtd_meta_description;

      if (qtd_diff_falta_meta_description > 0) {
        document
          .querySelector('.caracteres-meta-description')
          .classList.remove('text-success');
        document
          .querySelector('.caracteres-meta-description')
          .classList.add('text-danger');

        document.querySelector(
          '.caracteres-meta-description span.falta-resta'
        ).innerHTML = 'Faltam';

        document.querySelector(
          '.caracteres-meta-description span.caracteres-description'
        ).innerHTML = qtd_diff_falta_meta_description;

        document.querySelector(
          '.caracteres-meta-description span.catacter-falta-resta'
        ).innerHTML = 'caracteres';

        if (qtd_diff_falta_meta_description === 1) {
          document.querySelector(
            '.caracteres-meta-description span.falta-resta'
          ).innerHTML = 'Falta';
          document.querySelector(
            '.caracteres-meta-description span.catacter-falta-resta'
          ).innerHTML = 'caractere';
        }
      } else {
        document
          .querySelector('.caracteres-meta-description')
          .classList.remove('text-danger');
        document
          .querySelector('.caracteres-meta-description')
          .classList.add('text-success');

        const qtd_falta = 130;
        const qtd_diff_resta_meta_description =
          qtd_falta - qtd_meta_description;

        document.querySelector(
          '.caracteres-meta-description span.falta-resta'
        ).innerHTML = 'Restam';

        document.querySelector(
          '.caracteres-meta-description span.caracteres-description'
        ).innerHTML = qtd_diff_resta_meta_description;

        document.querySelector(
          '.caracteres-meta-description span.catacter-falta-resta'
        ).innerHTML = 'caracteres';

        if (qtd_diff_resta_meta_description === 1) {
          document.querySelector(
            '.caracteres-meta-description span.falta-resta'
          ).innerHTML = 'Resta';

          document.querySelector(
            '.caracteres-meta-description span.catacter-falta-resta'
          ).innerHTML = 'caractere';
        }
      }
    });
  }, [editor, id]);

  const handleChangeTitle = useCallback(e => {
    let val = e.target.value;
    const valMeta = document.querySelector('input#meta_title').value;
    if (valMeta.length > 0)
      document.querySelector('input#meta_title').value = val.substr(0, 65);
    const qtd = val.length;
    const qtd_resta = 30;
    const qtd_diff_resta = qtd_resta - qtd;

    if (qtd <= 65) {
      if (qtd_diff_resta > 0) {
        document
          .querySelector('.caracteres-meta-title')
          .classList.remove('text-success');
        document
          .querySelector('.caracteres-meta-title')
          .classList.add('text-danger');
        document.querySelector(
          '.caracteres-meta-title span.falta-resta'
        ).innerHTML = 'Faltam';

        document.querySelector(
          '.caracteres-meta-title span.caracteres-title'
        ).innerHTML = qtd_diff_resta;

        document.querySelector(
          '.caracteres-meta-title span.catacter-falta-resta'
        ).innerHTML = 'caracteres';

        if (qtd_diff_resta === 1) {
          document.querySelector(
            '.caracteres-meta-title  span.falta-resta'
          ).innerHTML = 'Falta';
          document.querySelector(
            '.caracteres-meta-title  span.catacter-falta-resta'
          ).innerHTML = 'caractere';
        }
      } else {
        document
          .querySelector('.caracteres-meta-title')
          .classList.remove('text-danger');
        document
          .querySelector('.caracteres-meta-title')
          .classList.add('text-success');
        const qtd_falta = 65;
        const qtd_diff_falta = qtd_falta - qtd;

        document.querySelector('span.falta-resta').innerHTML = 'Restam';

        document.querySelector(
          'span.caracteres-title'
        ).innerHTML = qtd_diff_falta;

        document.querySelector('span.catacter-falta-resta').innerHTML =
          'caracteres';

        if (qtd_diff_falta === 1) {
          document.querySelector('span.falta-resta').innerHTML = 'Resta';

          document.querySelector('span.catacter-falta-resta').innerHTML =
            'caractere';
        }
      }
    }

    val = val.replace(/[ÀÁÂÃÄÅàáâãäå]/g, 'a');
    val = val.replace(/[ÈÉÊËèéêë]/g, 'e');
    val = val.replace(/[ÍÌÎÏíìîï]/g, 'i');
    val = val.replace(/[ÓÒÕÔÖóòõôö]/g, 'o');
    val = val.replace(/[ÚÙÛÜúùûü]/g, 'u');
    val = val.replace(/[Çç]/g, 'c');
    val = val.replace(/[^a-z0-9\s]/gi, '');
    val = val.replace(/ /gi, '-');

    document.querySelector('input#url').value = val.toLowerCase();
  }, []);

  const handleChangeMetaTitle = useCallback(e => {
    const val = e.target.value;
    const qtd = val.length;
    const qtd_resta = 30;
    const qtd_diff_resta = qtd_resta - qtd;

    if (qtd_diff_resta > 0) {
      document
        .querySelector('.caracteres-meta-title')
        .classList.remove('text-success');
      document
        .querySelector('.caracteres-meta-title')
        .classList.add('text-danger');
      document.querySelector(
        '.caracteres-meta-title span.falta-resta'
      ).innerHTML = 'Faltam';

      document.querySelector(
        '.caracteres-meta-title span.caracteres-title'
      ).innerHTML = qtd_diff_resta;

      document.querySelector(
        '.caracteres-meta-title span.catacter-falta-resta'
      ).innerHTML = 'caracteres';

      if (qtd_diff_resta === 1) {
        document.querySelector(
          '.caracteres-meta-title  span.falta-resta'
        ).innerHTML = 'Falta';
        document.querySelector(
          '.caracteres-meta-title  span.catacter-falta-resta'
        ).innerHTML = 'caractere';
      }
    } else {
      document
        .querySelector('.caracteres-meta-title')
        .classList.remove('text-danger');
      document
        .querySelector('.caracteres-meta-title')
        .classList.add('text-success');
      const qtd_falta = 65;
      const qtd_diff_falta = qtd_falta - qtd;

      document.querySelector('span.falta-resta').innerHTML = 'Restam';

      document.querySelector(
        'span.caracteres-title'
      ).innerHTML = qtd_diff_falta;

      document.querySelector('span.catacter-falta-resta').innerHTML =
        'caracteres';

      if (qtd_diff_falta === 1) {
        document.querySelector('span.falta-resta').innerHTML = 'Resta';

        document.querySelector('span.catacter-falta-resta').innerHTML =
          'caractere';
      }
    }
  }, []);

  const handleChangeMetaDescription = useCallback(e => {
    const val = e.target.value;
    const qtd = val.length;
    const qtd_resta = 120;
    const qtd_diff_resta = qtd_resta - qtd;

    if (qtd_diff_resta > 0) {
      document
        .querySelector('.caracteres-meta-description')
        .classList.remove('text-success');
      document
        .querySelector('.caracteres-meta-description')
        .classList.add('text-danger');

      document.querySelector(
        '.caracteres-meta-description span.falta-resta'
      ).innerHTML = 'Faltam';

      document.querySelector(
        '.caracteres-meta-description span.caracteres-description'
      ).innerHTML = qtd_diff_resta;

      document.querySelector(
        '.caracteres-meta-description span.catacter-falta-resta'
      ).innerHTML = 'caracteres';

      if (qtd_diff_resta === 1) {
        document.querySelector(
          '.caracteres-meta-description span.falta-resta'
        ).innerHTML = 'Falta';
        document.querySelector(
          '.caracteres-meta-description span.catacter-falta-resta'
        ).innerHTML = 'caractere';
      }
    } else {
      document
        .querySelector('.caracteres-meta-description')
        .classList.remove('text-danger');
      document
        .querySelector('.caracteres-meta-description')
        .classList.add('text-success');

      const qtd_falta = 130;
      const qtd_diff_falta = qtd_falta - qtd;

      document.querySelector(
        '.caracteres-meta-description span.falta-resta'
      ).innerHTML = 'Restam';

      document.querySelector(
        '.caracteres-meta-description span.caracteres-description'
      ).innerHTML = qtd_diff_falta;

      document.querySelector(
        '.caracteres-meta-description span.catacter-falta-resta'
      ).innerHTML = 'caracteres';

      if (qtd_diff_falta === 1) {
        document.querySelector(
          '.caracteres-meta-description span.falta-resta'
        ).innerHTML = 'Resta';

        document.querySelector(
          '.caracteres-meta-description span.catacter-falta-resta'
        ).innerHTML = 'caractere';
      }
    }
  }, []);

  const handleChangeUrl = useCallback(e => {
    const val = e.target.value;
    document.querySelector('input#url').value = val.toLowerCase();
  }, []);

  const getImageCapa = useCallback(image => {
    setImageCapa(image);
  }, []);

  const getImageBanner = useCallback(image => {
    setImageBanner(image);
  }, []);

  const handleSubmit = useCallback(
    async ({
      titulo,
      meta_title,
      categoria,
      autor,
      url,
      meta_description,
      tags,
    }) => {
      const materiaTexto = editor.getData();
      const formData = new FormData();
      formData.append('title', titulo);
      formData.append('meta_title', meta_title);
      formData.append('category_id', categoria);
      formData.append('user_id', autor);
      formData.append('slug', url);
      formData.append('description', meta_description);
      formData.append('tags', tags);
      formData.append('thumbnail', imageCapa);
      formData.append('banner', imageBanner);
      formData.append('content', materiaTexto);

      const response = await api.post(`matters/${id}`, formData);

      if (response.data) {
        swal(
          'Edição realizada!',
          'A edição foi realizada com sucesso',
          'success'
        ).then(_ => {
          document.querySelector('#titulo').value = '';
          document.querySelector('#meta_title').value = '';
          document.querySelector('#categoria').value = '';
          document.querySelector('#autor').value = '';
          document.querySelector('#url').value = '';
          document.querySelector('#meta_description').value = '';
          document.querySelector('#tags').value = '';
          setImageCapa([]);
          setImageBanner([]);
          editor.setData('');
          history.push(`${process.env.PUBLIC_URL}/materias/listar`);
        });
      } else {
        swal(
          'Erro na edição!',
          'Ocorreu um erro na edição tente novamente',
          'error'
        );
      }
    },
    [editor, id, imageBanner, imageCapa]
  );

  return (
    <div className="container-fluid">
      <div className="row d-flex">
        <div className="col-lg-9">
          <div className="card income p-3">
            <h3 className="box-title">Editar matéria</h3>

            <Form
              initialData={materia}
              onSubmit={handleSubmit}
              className="form-horizontal mb-2 materia"
              id="formAdmin"
            >
              <div className="box-body">
                <div className="form-group mb-3">
                  <label htmlFor="titulo" className="control-label">
                    Título
                  </label>
                  <Input
                    className="form-control"
                    name="titulo"
                    placeholder="Titulo"
                    onChange={handleChangeTitle}
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="meta_title" className="control-label">
                    Meta Title
                  </label>
                  <Input
                    className="form-control"
                    name="meta_title"
                    placeholder="Titulo da Página"
                    minLength="30"
                    maxLength="65"
                    onChange={handleChangeMetaTitle}
                  />
                  <small className="caracteres-meta-title text-danger">
                    <span className="falta-resta">Faltam</span>{' '}
                    <span className="caracteres-title">30</span>{' '}
                    <span className="catacter-falta-resta">caracteres.</span>
                  </small>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="categoria" className="control-label">
                    Categoria
                  </label>
                  <Select
                    name="categoria"
                    options={categorias}
                    value={materia.categoria}
                    className="form-control"
                    placeholder="Categoria"
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="autor" className="control-label">
                    Autor
                  </label>
                  <Select
                    className="form-control"
                    name="autor"
                    options={autores}
                    value={materia.autor}
                    placeholder="Autor"
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="titulo-page" className="control-label">
                    URL da materia
                  </label>
                  <div className="d-flex flex-column">
                    <label
                      htmlFor="titulo-page"
                      className="form-control url d-flex align-items-center"
                    >
                      https://localhost:3000/blog/
                      <Input
                        className="border-0 form-control url bg-transparent px-0 outline-0"
                        name="url"
                        placeholder="URL"
                        required="required"
                        onChange={handleChangeUrl}
                      />
                    </label>
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="meta_description" className="control-label">
                    Meta Description
                  </label>
                  <Input
                    multiline
                    className="w-100 description form-control"
                    minLength="120"
                    maxLength="130"
                    name="meta_description"
                    onChange={handleChangeMetaDescription}
                  />
                  <small className="caracteres-meta-description text-danger">
                    <span className="falta-resta">Faltam</span>{' '}
                    <span className="caracteres-description">120</span>{' '}
                    <span className="catacter-falta-resta">caracteres.</span>
                  </small>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="editor" className="control-label">
                    Matéria
                  </label>
                  <textarea
                    id="editor"
                    className="form-control"
                    rows="10"
                    cols="80"
                    name="materia"
                  >
                    conteudo
                  </textarea>
                </div>

                <div className="form-group mb-3">
                  <label className="control-label">Imagem Capa</label>
                  <ImageInput
                    name="imagem_capa"
                    getImage={getImageCapa}
                    image={materia.urlcapa}
                  />
                  <small>Enviar imagem de até 1mb.</small>
                </div>

                <div className="form-group mb-3">
                  <label className="control-label">Imagem Banner</label>
                  <ImageInput
                    name="imagem_banner"
                    getImage={getImageBanner}
                    image={materia.urlbanner}
                  />
                  <small>Enviar imagem de até 1mb.</small>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="tags" className="control-label">
                    Tags
                  </label>
                  <Input
                    className="form-control"
                    name="tags"
                    placeholder="Tags"
                  />
                  <small>
                    Tags devem ser separados por &apos;;&apos; ponto e vírgula.
                  </small>
                </div>
              </div>

              <div className="box-footer d-flex justify-content-between">
                <button
                  type="button"
                  onClick={() => history.goBack()}
                  className="btn btn-default"
                >
                  Voltar
                </button>
                <button
                  type="submit"
                  className="btn btn-primary pull-right btn-materia"
                >
                  Editar
                </button>
              </div>
            </Form>
            <div className="alert w-100 display-none msg" role="alert" />
          </div>
        </div>
      </div>
    </div>
  );
}
