import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert';

import api from '~/services/api';
import history from '~/services/history';

export default function Listar() {
  const [show, setShow] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [id, setId] = useState(0);
  const [categoriaName, setCategoriaName] = useState('');

  useEffect(() => {
    api.get('categories').then(response => {
      setCategorias(response.data);
    });
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleShow = useCallback((idCategoria, categoria) => {
    setId(idCategoria);
    setCategoriaName(categoria);
    setShow(true);
  }, []);

  const handleClickApagar = useCallback(async () => {
    const response = await api.delete(`categories/${id}`);
    if (response.data) {
      Swal({
        title: 'Sucesso!',
        text: 'Categoria apagada!',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(_ => {
        setCategorias(categorias.filter(categoria => categoria.id !== id));
        setShow(false);
      });
    } else {
      Swal('Erro', 'Erro ao apagar a categoria tente novamente.', 'error');
    }
  }, [categorias, id]);

  return (
    <>
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-lg-7">
              <div className="card income p-3">
                <h3 className="box-title">Listar categorias</h3>

                <form className="form-horizontal" method="post">
                  <div className="box-body table-responsive">
                    {categorias.length > 0 ? (
                      <table className="display table table-striped dataTable">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th width="100" className="no-sort" />
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th width="100" className="no-sort" />
                          </tr>
                        </tfoot>
                        <tbody>
                          {categorias.map(categoria => (
                            <tr key={categoria.id}>
                              <td>{categoria.id}</td>
                              <td>{categoria.name}</td>
                              <td className="text-right">
                                <Link
                                  to={`${process.env.PUBLIC_URL}/categorias/editar/${categoria.id}`}
                                >
                                  <i className="fa fa-pencil text-info" />
                                </Link>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  className="border-0 bg-transparent outline-0 modalBtn"
                                  onClick={() =>
                                    handleShow(categoria.id, categoria.name)
                                  }
                                >
                                  <i className="fa fa-trash text-danger" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p className="px-2">
                        Sem categorias inseridas até o momento.
                      </p>
                    )}
                  </div>
                  <div className="box-footer d-flex justify-content-between">
                    <button
                      type="button"
                      onClick={() => history.goBack()}
                      className="btn btn-default"
                    >
                      Voltar
                    </button>
                    <Link
                      to={`${process.env.PUBLIC_URL}/categorias/cadastrar`}
                      className="btn btn-primary pull-right"
                    >
                      Cadastrar
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apagar categoria: {categoriaName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>ID:</b> <span className="id">{id}</span> <br />
          <b>Nome:</b> <span className="nome">{categoriaName}</span> <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="danger" onClick={handleClickApagar}>
            Apagar
          </Button>
        </Modal.Footer>
      </Modal>
      ;
    </>
  );
}
