import React, { useCallback } from 'react';
import { Form, Input } from '@rocketseat/unform';
import swal from 'sweetalert';

import api from '~/services/api';
import history from '~/services/history';

export default function Cadastrar() {
  const handleSubmit = useCallback(async ({ name, email, password }) => {
    const formData = new URLSearchParams();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('password', password);
    const response = await api.post('users', formData);

    if (response.data) {
      swal(
        'Cadastro realizado!',
        'O cadastro foi realizado com sucesso',
        'success'
      ).then(_ => {
        history.push(`${process.env.PUBLIC_URL}/usuarios/listar`);
      });
    } else {
      swal(
        'Erro no cadastro!',
        'Ocorreu um erro no cadastro tente novamente',
        'error'
      );
    }
  }, []);

  return (
    <section>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-lg-6">
            <div className="card income p-3">
              <h3 className="box-title">Cadastrar Usuário</h3>

              <Form
                onSubmit={handleSubmit}
                className="form-horizontal mb-2"
                id="formAdmin"
              >
                <div className="form-group">
                  <label htmlFor="categoria" className="col-sm-3 control-label">
                    Nome
                  </label>
                  <div className="col-sm-12">
                    <Input
                      className="form-control"
                      name="name"
                      placeholder="Nome"
                      required="required"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="categoria" className="col-sm-3 control-label">
                    Login
                  </label>
                  <div className="col-sm-12">
                    <Input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="E-mail"
                      required="required"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="categoria" className="col-sm-3 control-label">
                    Senha
                  </label>
                  <div className="col-sm-12">
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Senha"
                      required="required"
                    />
                  </div>
                </div>

                <div className="box-footer mt-3 d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="btn btn-default"
                  >
                    Voltar
                  </button>
                  <button type="submit" className="btn btn-primary pull-right">
                    Cadastrar
                  </button>
                </div>
              </Form>

              <div className="alert w-100 display-none msg" role="alert" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
