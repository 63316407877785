import React, { useCallback } from 'react';
import { Form, Input } from '@rocketseat/unform';
import swal from 'sweetalert';

import api from '~/services/api';
import history from '~/services/history';

export default function Cadastrar() {
  const handleSubmit = useCallback(async ({ name }) => {
    const formData = new URLSearchParams();
    formData.append('name', name);
    const response = await api.post(`categories`, formData);

    if (response.data) {
      swal(
        'Cadastro realizado!',
        'O cadastro foi realizado com sucesso',
        'success'
      ).then(_ => {
        history.push(`${process.env.PUBLIC_URL}/categorias/listar`);
      });
    } else {
      swal(
        'Erro no cadastro!',
        'Ocorreu um erro no cadastro tente novamente',
        'error'
      );
    }
  }, []);

  return (
    <section>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-lg-6">
            <div className="card income p-3">
              <h3 className="box-title">Cadastrar Categoria</h3>

              <Form
                onSubmit={handleSubmit}
                className="form-horizontal mb-2"
                id="formAdmin"
              >
                <div className="form-group">
                  <label htmlFor="categoria" className="col-sm-3 control-label">
                    Categoria
                  </label>
                  <div className="col-sm-9">
                    <Input
                      className="form-control"
                      name="name"
                      placeholder="Categoria"
                      required="required"
                    />
                    <small className="caracteres-nome">
                      <span className="caracteres">32</span> caracteres
                      restantes.
                    </small>
                  </div>
                </div>

                <div className="box-footer mt-3 d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="btn btn-default"
                  >
                    Voltar
                  </button>
                  <button type="submit" className="btn btn-primary pull-right">
                    Cadastrar
                  </button>
                </div>
              </Form>

              <div className="alert w-100 display-none msg" role="alert" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
