import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import 'jquery-mousewheel';

function SideNavbar() {
  useEffect(() => {
    if ($(window).outerWidth() > 992) {
      $('nav.side-navbar').mCustomScrollbar({
        scrollInertia: 200,
      });
    }
  }, []);

  function handleClickOption(element) {
    const aria = $(`.${element}`).attr('aria-expanded');

    if (aria === 'true') {
      $(`.${element}`).attr('aria-expanded', 'false');
    } else {
      $(`.${element}`).attr('aria-expanded', 'true');
    }

    $(`#${element}`)
      .stop()
      .slideToggle();
  }

  return (
    <nav className="side-navbar">
      <div className="side-navbar-wrapper">
        <div className="sidenav-header d-flex align-items-center justify-content-center">
          <div className="sidenav-header-inner text-center">
            <h2 className="h5">
              <Link to={`${process.env.PUBLIC_URL}/home`}>Administração</Link>
            </h2>
          </div>
          <div className="sidenav-header-logo">
            <h2 className="h5 brand-small">
              <Link to={`${process.env.PUBLIC_URL}/home`}>Admin</Link>
            </h2>
          </div>
        </div>
        <div className="admin-menu">
          <h5 className="sidenav-heading">Menu</h5>
          <ul id="side-main-menu" className="side-menu list-unstyled">
            <li>
              <button
                type="button"
                aria-expanded="false"
                data-toggle="collapse"
                className="border-0 outline-0 text-left categorias"
                onClick={() => handleClickOption('categorias')}
              >
                <i className="fa fa-list" />
                Categorias
              </button>
              <ul id="categorias" className="collapse list-unstyled ">
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/categorias/cadastrar`}>
                    <i className="fa fa-plus" />
                    Cadastrar
                  </Link>
                </li>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/categorias/listar`}>
                    <i className="fa fa-eye" />
                    Listar
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                aria-expanded="false"
                data-toggle="collapse"
                className="border-0 outline-0 text-left materias"
                onClick={() => handleClickOption('materias')}
              >
                <i className="fa fa-newspaper-o" />
                Matérias
              </button>
              <ul id="materias" className="collapse list-unstyled ">
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/materias/cadastrar`}>
                    <i className="fa fa-plus" />
                    Cadastrar
                  </Link>
                </li>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/materias/listar`}>
                    <i className="fa fa-eye" />
                    Listar
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <button
                type="button"
                aria-expanded="false"
                data-toggle="collapse"
                className="border-0 outline-0 text-left usuarios"
                onClick={() => handleClickOption('usuarios')}
              >
                <i className="fa fa-user" />
                Usuários
              </button>
              <ul id="usuarios" className="collapse list-unstyled ">
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/usuarios/cadastrar`}>
                    <i className="fa fa-plus" />
                    Cadastrar
                  </Link>
                </li>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/usuarios/listar`}>
                    <i className="fa fa-eye" />
                    Listar
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/leads`}>
                <i className="fa fa-envelope-o" />
                Leads
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default SideNavbar;
