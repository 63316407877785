import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import swal from 'sweetalert';

import api from '~/services/api';
import history from '~/services/history';

export default function Editar() {
  const params = useParams();
  const { id } = params;
  const [usuario, setUsuario] = useState([]);

  useEffect(() => {
    api.get(`users/${id}`).then(response => {
      setUsuario(response.data);
    });
  }, [id]);

  const handleSubmit = useCallback(
    async ({ name, email, password }) => {
      const formData = new URLSearchParams();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('password', password);
      const response = await api.put(`users/${id}`, formData);

      if (response.data) {
        swal(
          'Edição realizada!',
          'O edição foi realizada com sucesso',
          'success'
        ).then(_ => {
          history.push(`${process.env.PUBLIC_URL}/usuarios/listar`);
        });
      } else {
        swal(
          'Erro na edição!',
          'Ocorreu um erro na edição, tente novamente',
          'error'
        );
      }
    },
    [id]
  );

  return (
    <section>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-lg-6">
            <div className="card income p-3">
              <h3 className="box-title">Editar Usuário</h3>

              <Form
                initialData={usuario}
                onSubmit={handleSubmit}
                className="form-horizontal mb-2"
                id="formAdmin"
              >
                <div className="form-group">
                  <label htmlFor="categoria" className="col-sm-3 control-label">
                    Nome
                  </label>
                  <div className="col-sm-12">
                    <Input
                      className="form-control"
                      name="name"
                      placeholder="Nome"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="categoria" className="col-sm-3 control-label">
                    Login
                  </label>
                  <div className="col-sm-12">
                    <Input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="E-mail"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="categoria" className="col-sm-3 control-label">
                    Senha
                  </label>
                  <div className="col-sm-12">
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Senha"
                    />
                  </div>
                </div>

                <div className="box-footer mt-3 d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="btn btn-default"
                  >
                    Voltar
                  </button>
                  <button type="submit" className="btn btn-primary pull-right">
                    Editar
                  </button>
                </div>
              </Form>

              <div className="alert w-100 display-none msg" role="alert" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
