import React from 'react';

export default function Home() {
  return (
    <>
      <section className="dashboard-counts section-padding">
        <div className="container-fluid">
          <div className="row" />
        </div>
      </section>
    </>
  );
}
