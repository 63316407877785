import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert';
import $ from 'jquery';

import api from '~/services/api';
import history from '~/services/history';

export default function Listar() {
  const [show, setShow] = useState(false);
  const [materias, setMaterias] = useState([]);
  const [id, setId] = useState(0);
  const [materiaTitle, setMateriaTitle] = useState('');

  useEffect(() => {
    api.get('matters').then(response => {
      setMaterias(response.data);
    });
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleShow = useCallback((idMateria, materia) => {
    setId(idMateria);
    setMateriaTitle(materia);
    setShow(true);
  }, []);

  const handleClickApagar = useCallback(async () => {
    const response = await api.delete(`matters/${id}`);
    if (response.data) {
      Swal({
        title: 'Sucesso!',
        text: 'Matéria apagada!',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(_ => {
        setMaterias(materias.filter(materia => materia.id !== id));
        setShow(false);
      });
    } else {
      Swal('Erro', 'Erro ao apagar a matéria tente novamente.', 'error');
    }
  }, [id, materias]);

  const handleToggle = useCallback(async (idMateria, publicado) => {
    const FlPublicado = publicado === '1' ? '0' : '1';
    const formData = new FormData();
    formData.append('published', FlPublicado);
    const response = await api.patch(`matters/${idMateria}`, formData);
    if (response.data) {
      const classe = $(`#materia-${idMateria}`).attr('class');
      if (classe === 'fa fa-toggle-on text-success') {
        $(`#materia-${idMateria}`)
          .removeClass(classe)
          .addClass('fa fa-toggle-off text-danger');
      } else {
        $(`#materia-${idMateria}`)
          .removeClass(classe)
          .addClass('fa fa-toggle-on text-success');
      }
    }
  }, []);

  return (
    <>
      <section className="mt-5">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-12">
              <div className="card income p-3">
                <h3 className="box-title">Listar matérias</h3>

                <form className="form-horizontal" method="post">
                  <div className="box-body table-responsive">
                    {materias.length > 0 ? (
                      <table className="display table table-striped dataTable">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Titulo</th>
                            <th>Capa</th>
                            <th>Categoria</th>
                            <th>Criado por</th>
                            <th width="100" className="no-sort" />
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <th>ID</th>
                            <th>Titulo</th>
                            <th>Capa</th>
                            <th>Categoria</th>
                            <th>Criado por</th>
                            <th width="100" className="no-sort" />
                          </tr>
                        </tfoot>
                        <tbody>
                          {materias.map(materia => (
                            <tr key={materia.id}>
                              <td className="align-middle">{materia.id}</td>
                              <td className="align-middle">{materia.title}</td>
                              <td className="align-middle">
                                <img
                                  src={materia.thumbnail}
                                  alt={`Capa ${materia.title}`}
                                  height="32"
                                />
                              </td>
                              <td className="align-middle">
                                {materia.category}
                              </td>
                              <td className="align-middle">{materia.title}</td>
                              <td className="align-middle text-right">
                                <button
                                  type="button"
                                  className="border-0 bg-transparent outline-0 modalBtn"
                                  onClick={() =>
                                    handleToggle(materia.id, materia.published)
                                  }
                                >
                                  <i
                                    className={`fa fa-toggle-${
                                      materia.published === '1'
                                        ? 'on text-success'
                                        : 'off text-danger'
                                    }`}
                                    id={`materia-${materia.id}`}
                                  />
                                </button>
                                &nbsp;
                                <Link
                                  to={`${process.env.PUBLIC_URL}/materias/editar/${materia.id}`}
                                >
                                  <i className="fa fa-pencil text-info" />
                                </Link>
                                &nbsp;
                                <button
                                  type="button"
                                  className="border-0 bg-transparent outline-0 modalBtn"
                                  onClick={() =>
                                    handleShow(materia.id, materia.title)
                                  }
                                >
                                  <i className="fa fa-trash text-danger" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p className="px-2">
                        Sem matérias inseridas até o momento.
                      </p>
                    )}
                  </div>
                  <div className="box-footer d-flex justify-content-between">
                    <button
                      type="button"
                      onClick={() => history.goBack()}
                      className="btn btn-default"
                    >
                      Voltar
                    </button>
                    <Link
                      to={`${process.env.PUBLIC_URL}/materias/cadastrar`}
                      className="btn btn-primary pull-right"
                    >
                      Cadastrar
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apagar matéria: {materiaTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>ID:</b> <span className="id">{id}</span> <br />
          <b>Nome:</b> <span className="nome">{materiaTitle}</span> <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="danger" onClick={handleClickApagar}>
            Apagar
          </Button>
        </Modal.Footer>
      </Modal>
      ;
    </>
  );
}
